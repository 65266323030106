// Function for converting a px based font-size to rem.
@function calculateRem($size) {
  $remSize: $size / 16px;
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

html{
  font-size:16px;
}

$bodyFont:'Alegreya Sans',sans-serif;
$menuFont: 'Open Sans Condensed', sans-serif;
$gazellesRed:#dc2025;



//-------------------

body{
  font-family: $bodyFont;
  //font-size: (11px/16px)*1rem;

  @include fontSize(11px);

}

h2{
  //font-size:1.875rem;
  @include fontSize(30px);
  font-weight:700;
}

p{
  @include fontSize(14px);
}

nav{
  font-family: $menuFont;
  font-weight:700;
}



.navbar-default{
  background-image:none;
  background-color:#eee;

  .navbar-nav > li {
    @include fontSize(16px);

  }
}


.navbar-collapse{
  padding-left:0;
  padding-right:0;
}

.navbar{
  border-radius:0;
  margin-bottom: 10px;
}

@media (max-width: 760px) {
  ul.navbar-nav {
    padding-left: 10px;
  }
}

.banner{
  background-color:$gazellesRed;
  height:calculateRem(100px);

}

.banner,.footer{
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.banner img{
  padding:1rem;
  max-width:100%;
  max-height:100%;
}

.content{
  margin-top:30px;

}

.footer{
  height:40px;
  background-color: $gazellesRed;
  line-height:40px;


  vertical-align: middle;


  .copyright{

    color:#fff;
    text-align: right;
  }

  .social{
    font-size:2em;
    i {
      color: white;
    }

    a{
      padding:0.2em;
    }
  }
}

.left-block,.right-block{
  padding-left:30px;
  padding-right:30px;
}


h2.page-title{
  margin-top:0;
  color:black;//#550000;
}


//HOME


#intro {
  margin-bottom: 30px;
  width:90%;

  h2.page-title{
    @include fontSize(35px);
  }
  p {
    @include fontSize(21px);
    line-height:1.3;
  }

}

@media (max-width: 760px) {
  #intro {
    width:100%;
  }
}



#next-race {
  margin-bottom: 20px;
  padding: 20px;
  border: 5px solid #AAA;
  background-color:#EEE;
  margin-left:-15px;

  h3{
    margin-top:0;
  }
  p {
    @include fontSize(16px);
  }
  .next-race{
    color:#880000;
    font-weight:bold;

    a{
      color:inherit;
      text-decoration:none;
    }
  }
}

@media (max-width: 760px) {
  #next-race {
    margin-left:0;
  }
}



ul.homenews, ul.fixtureslist1, ul.fixtureslist2,ul.racenews ,ul.racenewsarchive  {
  list-style-type: circle;
  padding-left: 1em;
  li {
    @include fontSize(14px);
    margin-bottom: calculateRem(4px);
  }
}

ul.homenews, ul.fixtureslist1, ul.fixtureslist2  {
  li {
    @include fontSize(14px);
    margin-bottom: calculateRem(4px);
  }
}



ul.racenews{
  li{
    @include fontSize(14px);
    margin-bottom: calculateRem(4px);
  }
}

//NEWS
.headline{
  @include fontSize(18px);
  margin-top: calculateRem(16px);
  margin-bottom:calculateRem(0.2*16px);

  a{
    color:inherit;

  }

  a:hover{
    cursor: default;
    text-decoration: none;
  }
}

ul.fixtureslist1{
  p{
    margin-bottom:0;

  }

  p.race-details{
    @include fontSize(11px);
  }
}
//COMMITTEE

table.committee {
  border-collapse: collapse;


  th, td {
    border: 1px solid #ddd;
    padding:0.2rem;
  }
}

//MEMBERSHIP
table.account {
  border-collapse: collapse;

  th, td {
    border: 1px solid #ddd;
    padding:0.2rem;
  }
}



//TRAINING
.training-schedule{
  border-bottom:3px solid #AAAAAA;
  margin-bottom:10px;
  padding-bottom:10px;

  ul{
    list-style-type: circle;
    padding-left: 1em;
  }

  .training-session {

    margin-bottom:10px;
    .training-date {
      @include fontSize(16px);
      margin-bottom: 0;
    }
    .training-text {
      @include fontSize(14px);
      margin-bottom: 0;
    }
    .training-description {
      @include fontSize(12px);
      margin-bottom: 0;
    }
  }
}



//RACE PAGES
.course-map{
  max-width:100%;
}
.history{
  li{
    @include fontSize(14px);
  }
}
.sponsors {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-top:20px;
  border-top:2px solid #BBB;
}

.sponsors img {
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 60%;
}

footer { width:100%; bottom:0; }
